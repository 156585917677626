.navbar {
	width: 100%;
	position: fixed;
	bottom: 0;
	height: 60px;
	background-color: whitesmoke;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	max-width: 800px;
	z-index: 5;
	border-top: 1px solid gray;
}

.link {
	text-decoration: none;
	cursor: pointer;
	color: #0e1317;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	align-items: center;
	justify-content: center;
}

.active {
	color: #1bad78;
}
