.card {
	width: 150px;
	height: 200px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0.3rem 0.5rem;
	color: white;
	cursor: pointer;
}

.card-title {
	font-family: 'Courgette', cursive;
	font-size: 1.5rem;
}

.new {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #0be881;
	padding: 3px 5px;
}

.likes {
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 5px;
	height: 30px;
}

.likes > p {
	font-size: 1.1rem;
	padding-right: 5px;
}

@media only screen and (max-width: 820px) {
	.card {
		width: 120px;
		height: 170px;
		font-size: 0.7rem;
	}

	.card-title {
		font-size: 1rem;
	}

	.likes > p {
		font-size: 0.7rem;
	}
}
