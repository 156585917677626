.home-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	/* overflow-y: scroll; */
}

.home {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 800px;
	height: 100%;
	/* rohan change */
	position: relative;
	background-color: #efefef;
	align-items: center;
}

.home-header {
	background-color: #efefef;
	justify-content: space-between;
	width: 100%;
	display: flex;
	height: 60px;
	max-width: 800px;
	align-items: center;
	color: black;
	font-size: 1.5rem;
	border-bottom: 1px solid gray;
}
.bodyTitle {
	background-color: #efefef;
	width: fit-content;
	padding: 0.6rem;
	font-size: 1.3rem;
	color: white;
	border-radius: 999px;
	font-family: 'Courgette', cursive;
}

.draft-header {
	background-color: #efefef;
	justify-content: space-between;
	width: 100%;
	display: flex;
	height: 60px;
	align-items: center;
	color: 0e1317;
	font-size: 1rem;
	border-bottom: 1px solid gray;
	/* padding-left: 15px; */
}
@font-face {
	font-family: 'Quincy';
	font-weight: bold;
	src: local('Quincy'),
		url('../../fonts/QuincyCF-Bold.otf') format('opentype');
}

.logo {
	display: flex;
	align-items: flex-start;
	font-weight: bold;
	justify-content: center;
	padding-left: 5px;
}

.logo > img {
	padding-top: 9px;
	object-fit: 'center';
	width: 44px;
	height: 44px;
}

.logo > p {
	margin-top: 1.7rem;
	padding-left: 5px;
	font-family: 'Quincy', sans-serif;
	font-weight: bold;
}

.cards-container {
	color: #efefef;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	overflow-y: scroll;
	flex: 1;
	margin-bottom: 3.75rem;
}

@media only screen and (max-width: 820px) {
	.home-container {
		width: 100%;
	}

	.logo > p {
		font-size: 1.4rem;
		margin-top: 1.7rem;
	}
}

.search {
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: #efefef;
	border-bottom: 1px solid black;
}

.search > input {
	outline-width: 0;
	font-size: 1rem;
	flex: 1;
	border: none;
	background-color: transparent;
	color: black;
}

.search-logo {
	color: black;
}

.searchResults {
	position: absolute;
	top: 7%;
	right: 5%;
	z-index: 999;
	background-color: rgb(247, 236, 236);
	width: fit-content;
	max-height: 30%;
	padding: 20px;
	border-radius: 10px;
	overflow-y: scroll;
}

.searchResult {
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: 200ms;
}

.searchResult > p {
	font-size: 15px;
	font-weight: 600;
	padding: 0 10px;
}

.searchResult:hover {
	opacity: 0.8;
	background-color: #e5e5e5;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #efefef;
}

::-webkit-scrollbar-thumb {
	background: #efefef;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background: #efefef;
}
