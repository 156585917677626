@font-face {
	font-family: 'Quincy';
	src: local('Quincy'), url('./fonts/QuincyCF-Regular.otf') format('opentype');
}

* {
	font-family: 'Quincy', sans-serif;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
	height: 100%;
	background-color: rgb(239, 239, 239);
	padding: 0;
	margin: 0;
}
