.newPost {
	width: 100%;
	max-width: 800px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #efefef;
	overflow-y: scroll;
}

.newPost::-webkit-scrollbar {
	display: none;
}

.editor {
	display: flex;
	flex-grow: 1;
	overflow-y: auto;
	flex-direction: column;
	line-height: 1.1;
	background-color: rgb(239, 239, 239) !important;
}

.wrapper-class {
	padding: 1rem;
	border: 1px solid #ccc;
}
.editor-class {
	background-color: rgb(239, 239, 239);
	padding: 1rem;
	border: 1px solid #ccc;
	overflow-y: auto;
	max-height: 80vh;
	line-height: 1.1;
}

.editor-class::-webkit-scrollbar {
	width: 10px;
}

.editor-class::-webkit-scrollbar-track {
	background: #efefef;
}

.editor-class::-webkit-scrollbar-thumb {
	background: #e0dfdf;
	border-radius: 5px;
}

.editor-class::-webkit-scrollbar-thumb:hover {
	background: #efefef;
}

.toolbar-class {
	border: 1px solid #ccc;
}

.newPost__Header {
	padding: 5px 10px;
	display: grid;
	place-items: center;
	margin-bottom: 0.8rem;
	/* background-color: rgb(0, 115, 255); */
}

.newPost__Header > input {
	outline: none;
	border: none;
	color: #0e1317;
	width: 100%;
	padding: 10px;
	text-align: center;
	background-color: rgb(259, 259, 259);
	border-radius: 999px;
}

.newPost__body {
	flex: 0.91;
	color: #0e1317;
	background-color: #efefef;
}

.newPost__footer {
	display: flex;
	justify-content: space-evenly;
	padding: 10px 20px;
	margin-bottom: 55px;
}

.newPost__footer > button {
	width: 40%;
	text-transform: capitalize;
}

.newPost__footer > button:hover {
	background-color: #1bad78;
	color: #fff;
	transition: 200ms;
}

.dialogBoxColor {
	padding: 20px;
	/* width: 100%; */
}

.coverColors {
	display: flex;
	flex-wrap: wrap;
}

.coverColor {
	margin: 10px;
	border-radius: 999px;
	cursor: pointer;
}

.coverColor:hover {
	opacity: 0.8;
}
