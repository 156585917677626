.profile-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	background-color: #efefef;
	overflow: hidden;
	flex-direction: column;
}

.profile {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 800px;
	height: 100%;
	color: black;
	background-color: #efefef;
	overflow-y: scroll;
}

.profile::-webkit-scrollbar {
	display: none;
}

.profile__header {
	font-size: 1rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	flex-direction: column;
	padding: 5px;
}

.profile__avatar {
	height: 45px !important;
	width: 45px !important;
	object-fit: contain;
}

.profile__headerTop {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.profile__headerTopUser {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.profile__headerTopUser > p {
	padding-left: 5px;
}

.profile__headerBottom {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.profile__headerBottomInfo {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 10px;
	width: 100%;
	font-weight: 500;
	font-size: 0.8rem;
	cursor: pointer;
}

.profile__headerBottomInfo > p:active {
	color: #1bad78;
}

.profile__headerBottomButtons > button {
	margin-top: 0.8rem;
	width: 100%;
	max-width: 400px;
	border-radius: 10px;
	text-transform: capitalize;
	background-color: #cfcfcf;
	color: #0e1317 !important;
	padding: 8px 15px;
}

.profile__headerBottomButtons {
	display: flex;
	justify-content: center;
	margin-top: 5px;
	flex-direction: column;
	align-items: center;
}

.headerBottom_follow_magazine {
	width: 100%;
	display: flex;
	justify-content: center;
}

.headerBottom_follow_magazine > button {
	width: 100%;
	max-width: 400px;
	border-radius: 10px;
	background-color: #1bad78;
	color: whitesmoke !important;
	padding: 10px;
}

.headerBottom_follow_magazine > button:hover {
	background-color: #1bad78 !important;
	color: rgb(46, 46, 46) !important;
	transition: 200ms !important;
}

.profile__headerBottomButtons > button {
	height: 90px !important;
	border-radius: 10px;
}

.profile__headerRight {
	display: flex;
}

.profile__headerRight > .MuiSvgIcon-root {
	cursor: pointer;
	font-size: 30px;
	margin-left: 15px;
}

.profile__nav {
	padding: 10px;
	border-bottom: 1px solid gray;
	display: flex;
	justify-content: space-evenly;
}

.profile__nav > p {
	color: black;
	cursor: pointer;
}

.profile__nav > p:hover {
	color: #1bad78;
	transition: 200ms;
}

.profile__body {
	color: black;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	overflow-y: scroll;
	margin-bottom: 3.75rem;
}

.profileDialog {
	padding: 30px;
	max-height: 300px;
}

.profileDialog > p {
	text-align: center;
	cursor: pointer;
	font-weight: 800;
	letter-spacing: 1.2px;
}

.profileDialog > p:hover {
	opacity: 0.8;
}

.newD {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.newD > p {
	font-weight: bold;
}

.newD > .MuiSvgIcon-root {
	cursor: pointer;
}

.dList {
	overflow-y: scroll;
}

.dList > p {
	padding: 10px;
	cursor: pointer;
}

.dList > p:hover {
	opacity: 0.8;
}

.dList::-webkit-scrollbar {
	display: none;
}

@media (max-width: 600px) {
	.profile__headerMiddle {
		flex: 1;
	}

	.profile__avatar {
		height: 40px !important;
		width: 40px !important;
		object-fit: contain;
	}
}
