.signin-container {
	width: 100%;
	display: flex;
	align-items: center;
	height: 100vh;
	flex-direction: column;
	position: relative;
	overflow-y: hidden;
}

.header-img {
	width: 100%;
	max-width: 800px;
	background-image: url(../../images/header-img.jpg);
	background-size: cover;
	background-position: center;
	height: 380px;
	margin-bottom: 1rem;
}

.title {
	display: flex;
	font-weight: bold;
	font-size: 2.5rem;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	color: #2f2e41;
	z-index: 3;
}

.signin-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	margin-bottom: 2rem;
}

.title > p {
	font-size: 1.3rem;
	text-align: center;
	color: #3a3a46;
	margin-bottom: 2rem;
}

.signin-btn {
	cursor: pointer;
	border-radius: 6px;
	outline: none;
	border: none;
	background-color: #147efb;
	font-weight: bold;
	color: white;
	font-size: 1.3rem;
	padding: 12px 10px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 260px;
	margin-bottom: 2rem;
	z-index: 3;
}

.signin-footer {
	background-image: url(../../images/signin-footer.svg);
	background-position: left center;
	background-repeat: round;
	background-size: contain;
	width: 100%;
	height: 100px;
	position: absolute;
	bottom: 0;
}

@media only screen and (max-width: 500px) {
	.header-img {
		clip-path: circle(80% at 50% 5%);
		justify-content: flex-start;
	}
}

@media only screen and (max-width: 400px) {
	.header-img {
		justify-content: flex-start;
		clip-path: circle(80% at 50% 20%);
	}
}

@media only screen and (min-width: 768px) {
	.signin-container {
		justify-content: center;
	}

	.main {
		position: absolute;
		top: 5rem;
		border-radius: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 80%;
		max-width: 800px;
		background-color: #dffbe1;
	}

	.header-img {
		flex: 1;
		margin-bottom: 0;
		width: 100%;
		height: 600px;
	}

	.title {
		flex: 1;
		padding: 10px;
	}
}
