.viewPost {
	/*  */
	width: 100%;
	max-width: 800px;
	height: 100%;
	color: #0e1317;
	/*  */
	background-color: #efefef;
	overflow-y: scroll;
	margin-bottom: 3.75rem;
	/* background-color: rgb(88, 88, 88); */
}

.viewPost::-webkit-scrollbar {
	display: none;
}

.viewPost__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	background-color: #efefef;
	border-bottom: 1px solid gray;
}

.viewPost > h2 {
	flex: 1;
	text-align: center;
	/* background-color: aquamarine; */
}

.viewPost__headerInfo {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.viewPost__headerInfo > p {
	padding-left: 10px;
}

.viewPost__headerInfo:hover {
	opacity: 0.8;
}

.viewPost__body {
	padding: 10px;
	background-color: #efefef; /*rgb(204, 204, 204);*/
	color: #0e1317;
}

.viewPost__footer {
	margin-bottom: 70px;
	display: flex;
	justify-content: flex-start;
	/* align-items: center; */
	flex-direction: column;
	padding: 10px 0;
}

.viewPost__footerIcons {
	display: flex;
	align-items: center;
	/* flex: 1; */
	/* background-color: rebeccapurple; */
}

.viewPost__footerIcon {
	display: flex;
	align-items: center;
	margin-right: 1rem;
}

.viewPost__footerIcon > .MuiSvgIcon-root {
	cursor: pointer;
}

/* .viewPost__footerIcon > .MuiSvgIcon-root:hover {
	color: rgb(41, 191, 41);
} */

.viewPost__footerIcon > p {
	font-weight: 500;
}

.viewPost__footerNewComment {
	margin-top: 10px;
	background-color: #dfdfdf;
	padding: 20px 10px;
	border-radius: 10px;
}

.viewPost__footerNewComment > input {
	background: transparent;
	border: none;
	outline: none;
	padding: 10px;
	width: 80%;
	font-size: 1rem;
}

.viewPost__footerComments {
	padding: 20px 30px;
	display: flex;
	flex-direction: column;
}

.viewPost__footerCommentsAvatar {
	display: flex;
	align-items: center;
}

.viewPost__footerCommentsAvatar > h3 {
	margin-left: 20px;
}

.viewPost__footerComments > p {
	margin-left: 20px;
}

.delete-button > .MuiButtonBase-root {
	background-color: red;
	color: #efefef;
}

.delete-button > .MuiButtonBase-root:hover {
	background-color: #efefef;
	color: red;
}

.draft-buttons {
	display: flex;
	padding: 10px;
	justify-content: space-between;
	width: 250px;
}
