.newMagazine {
	/*  */
	width: 100%;
	max-width: 800px;
	height: 100%;
	color: black;
	/*  */

	display: grid;
	place-items: center;
	overflow: scroll;
	background-color: white;
}

.newMagazine__add {
	display: flex;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	transition: 200ms;
	/* border: 1px dashed whitesmoke; */
	padding: 10px;
}

.newMagazine__add > .MuiSvgIcon-root {
	font-size: 70px !important;
}

.newMagazine__add > h2 {
	font-weight: 900;
	letter-spacing: 2px;
}

.newMagazine__add:hover {
	/* transform: scale(1.1); */
	transition: 200ms;
	color: #1bad78;
}

.newMagazine__dialog {
	padding: 40px;
}

.newMagazine__dialog > input {
	margin-top: 20px;
}

.newMagazine__dialogButtons {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	padding: 10px;
}

.newMagazine__dialogButtons > button {
	background-color: gray;
}

.newMagazine__dialogButtons > button:hover,
.newMagazine__previewButtons > button:hover,
.newMagazine__preview > button:hover {
	background-color: #1bad78;
}

.newMagazine__preview {
	/* margin: 30px 0; */
	margin-bottom: 100px;
}

.newMagazine__preview > button {
	margin-bottom: 80px;
	background-color: gray;
}

.newMagazine__previewButtons {
	display: flex;
	justify-content: center;
	padding: 10px;
}

.newMagazine__previewButtons > button {
	background-color: gray;
	margin-left: 10px;
}

.coverColors {
	margin-bottom: 1rem;
	width: 250px;
	height: 250px;
	border: 1px solid black;
	display: flex;
	align-items: center;
	padding: 0.5rem;
}

.coverColors > img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
